var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm.device === "pc" ? _c("div", {
    staticClass: "colo-clear-page"
  }, [_c("div", {
    staticClass: "view-site"
  }, [_c("span", {
    on: {
      click: function ($event) {
        return _vm.pathTo("https://hcp.coloclear.hicircle.com/");
      }
    }
  }, [_vm._v("View healthcare professional site")]), _c("span", {
    staticClass: "el-icon-right"
  })]), _c("div", {
    staticClass: "menu-bar"
  }, [_c("a", {
    staticClass: "brand-icon"
  }), _c("div", {
    staticClass: "navigation"
  }, [_c("el-menu", {
    staticClass: "el-menu-demo",
    attrs: {
      mode: "horizontal"
    },
    on: {
      select: _vm.handleSelect
    }
  }, [_c("el-menu-item", {
    attrs: {
      index: "1"
    }
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].product))]), _c("el-submenu", {
    attrs: {
      index: "2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].aboutUs))]), _c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].aboutUs))])], 2)], 1), _c("div", {
    staticClass: "operate-button"
  }, [_c("el-menu", {
    staticClass: "el-menu-demo",
    attrs: {
      mode: "horizontal"
    },
    on: {
      select: _vm.handleSelect
    }
  }, [_c("el-menu-item", {
    attrs: {
      index: "1"
    }
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].faq))]), _c("el-submenu", {
    attrs: {
      index: "2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm._v(_vm._s(_vm.language == "zh" ? "繁體中文" : "English"))]), _c("el-menu-item", {
    attrs: {
      index: "2-4-1"
    }
  }, [_vm._v("English")]), _c("el-menu-item", {
    attrs: {
      index: "2-4-2"
    }
  }, [_vm._v("繁體中文")])], 2)], 1)], 1)], 1), _c("div")]), _c("div", {
    staticClass: "banner",
    attrs: {
      id: "banner"
    }
  }, [_c("div", {
    staticClass: "banner-imgs"
  }, [_c("el-carousel", {
    ref: "slideCarousel",
    staticStyle: {
      height: "100%"
    },
    attrs: {
      height: "100%",
      interval: 6000
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("img", {
      attrs: {
        src: item,
        alt: ""
      }
    })]);
  }), 1)], 1), _c("div", {
    staticClass: "banner-content"
  }, [_vm._m(0), _c("div", {
    staticClass: "h1-2 mtb"
  }, [_c("span", [_vm._v("Colo"), _c("span", {
    staticClass: "blue"
  }, [_vm._v("Clear")]), _vm._v(_vm._s(_vm.langText[_vm.language].ColorectalCancerScreeningTest))])]), _c("div", {
    staticClass: "p-3"
  }, [_c("p", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerText1))])]), _c("div", {
    staticClass: "hero-list-wrapper"
  }, [_c("div", {
    staticClass: "list-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerTextLi1))])]), _c("div", {
    staticClass: "list-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerTextLi2))])]), _c("div", {
    staticClass: "list-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerTextLi3))])]), _c("div", {
    staticClass: "list-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerTextLi4))])]), _c("div", {
    staticClass: "list-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].bannerTextLi5))])])]), _c("div", {
    staticClass: "hero-button-box"
  }, [_c("a", {
    staticClass: "button w-inline-block",
    on: {
      click: _vm.notice
    }
  }, [_c("div", {
    staticClass: "button-text-2"
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].getColoClearNow))])]), _c("a", {
    staticClass: "button secondary w-inline-block",
    on: {
      click: _vm.notice
    }
  }, [_c("div", {
    staticClass: "button-text-2"
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].learnMore))])])])])]), _vm.language === "zh" ? _c("div", {
    staticClass: "section-usp",
    attrs: {
      id: "rtb"
    }
  }, [_vm._m(1)]) : _vm._e(), false ? _c("div", {
    staticClass: "section-screening"
  }, [_vm._m(2)]) : _vm._e(), _c("div", {
    staticClass: "section section-one"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_title))])]), _c("div", {
    staticClass: "section-content"
  }, [_c("div", {
    staticClass: "img"
  }, [_c("img", {
    attrs: {
      src: _vm.sectionTabImgList[Number(_vm.sectionActiveNames) - 1],
      alt: ""
    }
  })]), _c("div", {
    staticClass: "tab-content"
  }, [_c("el-collapse", {
    attrs: {
      accordion: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.sectionActiveNames,
      callback: function ($$v) {
        _vm.sectionActiveNames = $$v;
      },
      expression: "sectionActiveNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: _vm.langText[_vm.language].section1_collapase1_title,
      name: "1"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase1_content))])]), _c("el-collapse-item", {
    attrs: {
      title: _vm.langText[_vm.language].section1_collapase2_title,
      name: "2"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase2_content))])]), _c("el-collapse-item", {
    attrs: {
      title: _vm.langText[_vm.language].section1_collapase3_title,
      name: "3"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase3_content))])]), _c("el-collapse-item", {
    attrs: {
      title: _vm.langText[_vm.language].section1_collapase4_title,
      name: "4"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase4_content1))]), _c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase4_content2))]), _c("div", [_vm._v(_vm._s(_vm.langText[_vm.language].section1_collapase4_content3))])])], 1)], 1)])]), _c("div", {
    staticClass: "section section-two"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section2_title))])]), _c("div", {
    staticClass: "section-tabs"
  }, _vm._l(_vm.sectionTwoTabs, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab",
      class: _vm.sectionTwoTabIndex == index ? "active" : "",
      on: {
        click: function ($event) {
          _vm.sectionTwoTabIndex = index;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _c("div", {
    staticStyle: {
      width: "100%",
      "text-align": "center"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.sectionTwoImgList[_vm.sectionTwoTabIndex],
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "section section-three"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section3_title))])]), _c("div", {
    staticClass: "img"
  }, [_vm.language === "zh" ? _c("img", {
    attrs: {
      src: require("./assets/section3/total.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/section3/total_en.png"),
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "section section-four"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section4_title))])]), _c("div", {
    staticClass: "section-subtitle"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section4_subTitle))])]), _c("div", {
    staticClass: "img"
  }, [_vm.language === "zh" ? _c("a", {
    attrs: {
      href: "#"
    }
  }, [_c("img", {
    attrs: {
      src: require("./assets/section4/total.png"),
      alt: ""
    }
  })]) : _c("a", {
    attrs: {
      href: "#"
    }
  }, [_c("img", {
    attrs: {
      src: require("./assets/section4/total_en.png"),
      alt: ""
    }
  })])])]), _vm._m(3), _c("div", {
    staticClass: "section section-six"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_title))])]), _c("div", {
    staticClass: "section-subtitle"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_subTitle))])]), _c("div", {
    staticClass: "sample-content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("img", {
    attrs: {
      src: require("./assets/section6/group.svg"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "how-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item1_title))])]), _vm.language === "zh" ? _c("div", [_vm._v("您須於"), _c("strong", [_vm._v("下午4時30分前")]), _vm._v("預約次日的收件服務。")]) : _vm._e(), _vm.language === "en" ? _c("div", [_vm._v("Book a next-day courier service "), _c("strong", [_vm._v("before 4:30PM.")])]) : _vm._e(), _c("div", [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item1_content1))])]), _c("div", {
    on: {
      click: function ($event) {
        _vm.showSectionSixDialog = true;
      }
    }
  }, [_c("strong", {
    staticClass: "blue",
    staticStyle: {
      cursor: "pointer"
    }
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item1_content2))])])]), _c("div", {
    staticClass: "right"
  }, [_c("img", {
    attrs: {
      src: require("./assets/section6/copy.svg"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "how-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item2_title))])]), _vm.language === "zh" ? _c("div", [_c("span", [_vm._v("請於採樣後")]), _c("strong", [_vm._v("48小時內")]), _c("span", [_vm._v("將樣本送至我們的實驗室，避免樣本降解。")])]) : _vm._e(), _vm.language === "en" ? _c("div", [_vm._v("Drop off your sample directly at our lab "), _c("strong", [_vm._v("within 48 hours")]), _vm._v(" of collecting your sample to avoid sample degradation.")]) : _vm._e(), _c("div", {
    staticClass: "address"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item2_content1))]), _c("a", {
    staticClass: "link map",
    attrs: {
      href: "https://goo.gl/maps/CM4Tdn87KNeLVDcdA",
      target: "_blank"
    }
  }, [_vm._v("   " + _vm._s(_vm.langText[_vm.language].section6_item2_content2))])]), _c("div", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].section6_item2_content3))])])])]), _c("div", {
    staticClass: "section section-seven"
  }, [_c("div", {
    staticClass: "flex content"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("a", {
    attrs: {
      href: "https://eshop.medifasthk.com/en/coloclear-%E7%84%A1%E5%89%B5%E5%A4%A7%E8%85%B8%E7%99%8C%E5%9F%BA%E5%9B%A0%E7%AF%A9%E6%9F%A5%E6%B8%AC%E8%A9%A6"
    }
  }, [_vm.language == "zh" ? _c("img", {
    attrs: {
      src: require("./assets/section7/img1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/section7/img1_en.png"),
      alt: ""
    }
  })]), _c("a", {
    attrs: {
      href: "https://eshop.medifasthk.com/en/coloclear-%E7%84%A1%E5%89%B5%E5%A4%A7%E8%85%B8%E7%99%8C%E5%9F%BA%E5%9B%A0%E7%AF%A9%E6%9F%A5%E6%B8%AC%E8%A9%A6"
    }
  }, [_vm.language == "zh" ? _c("img", {
    attrs: {
      src: require("./assets/section7/img2.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/section7/img2_en.png"),
      alt: ""
    }
  })])]), _vm._m(4)])]), _c("div", {
    staticClass: "section section-eight"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section8_title))])]), _vm.language === "zh" ? _c("img", {
    attrs: {
      src: require("./assets/section8/total.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/section8/total_en.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "section section-nine"
  }, [_c("div", {
    staticClass: "section-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section9_title))])]), _c("div", {
    staticClass: "section-subtitle"
  }, [_c("span", [_vm._v(_vm._s(_vm.langText[_vm.language].section9_subTitle))])]), _c("el-collapse", {
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.sectionNineActiveName,
      callback: function ($$v) {
        _vm.sectionNineActiveName = $$v;
      },
      expression: "sectionNineActiveName"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "ColoClear是甚麼？",
      name: "1"
    }
  }, [_c("div", [_vm._v("ColoClear是用於大腸癌篩檢的非入侵性，家用糞便樣本採集套裝。ColoClear測試能從您的糞便樣本偵測結直腸癌以及良性腫瘤。")])]), _c("el-collapse-item", {
    attrs: {
      title: "甚麼人士適合使用ColoClear？",
      name: "2"
    }
  }, [_c("div", [_vm._v("普遍來說，40歲或以上人士患上大腸癌的風險較高，當中包括但不限於以下人士：")]), _c("div", [_vm._v("·有大腸癌家族病史")]), _c("div", [_vm._v("·有惡性結直腸腫瘤病史")]), _c("div", [_vm._v("·曾有大腸瘜肉")]), _c("div", [_vm._v("·有盲腸炎病史及/或曾切除盲腸")]), _c("div", [_vm._v("·有影響腸道的徵狀（譬如腹瀉、便秘、腹脹及便血），包括較年輕的人士（18歲以上）")])]), _c("el-collapse-item", {
    attrs: {
      title: "我不應在甚麼時候使用ColoClear？",
      name: "3"
    }
  }, [_c("div", [_vm._v("如果您遇到以下任何情況，請暫時不要使用ColoClear 測試套裝。")]), _c("div", [_vm._v("·月經週期")]), _c("div", [_vm._v("·腹瀉 / 水狀大便")]), _c("div", [_vm._v("·血便 （譬如痔瘡出血）")]), _c("div", [_vm._v("上述情況會增加糞便樣本被血液和/或異常細胞污染的可能性，這些血液和/或異常細胞不一定是由大腸癌引起的。因此，請不要在上述情況下進行測試。")]), _c("div", [_vm._v("已被診斷出患有以下疾病之人士，亦請不要使用 ColoClear測試套裝。")]), _c("div", [_vm._v("·家族性腺瘤性瘜肉病 (FAP)")]), _c("div", [_vm._v("·克羅氏症")]), _c("div", [_vm._v("·炎症性腸病 (IBD)，包括慢性潰瘍性結腸炎 (CUC)")])]), _c("el-collapse-item", {
    attrs: {
      title: "ColoClear如何運作？",
      name: "4"
    }
  }, [_c("div", [_vm._v("ColoClear套裝內包含兩支樣本瓶，分別用於進行兩個不同的測試。其中一個將從您的糞便樣本提取基因，然後檢查是否有大腸癌及/或癌前瘜肉生物標記物。另一個測試則是標準的糞便免疫化學 (FIT) 測試，通過檢測糞便樣本中的少量血跡，分析您有否患上大腸癌。")]), _c("div", [_vm._v("基因測試則是檢測可能與大腸癌及/或癌前瘜肉相關的基因突變。糞便免疫化學測試檢測可能由大腸癌及/或癌前瘜肉造成的少量血跡。由於出血的情況未必一直持續，因此相對大腸鏡檢查，糞便免疫化學測試檢測大腸癌的靈敏度介乎於77%-94%。")]), _c("div", [_vm._v("結合兩個測試能檢測多項生物標記物，提升檢測靈敏度至96%。")])]), _c("el-collapse-item", {
    attrs: {
      title: "為甚麼我應進行大腸癌篩檢？",
      name: "5"
    }
  }, [_c("div", [_vm._v("根據美國癌症協會的資料顯示，大腸癌是大腸或直腸細胞的異常生長，有機會侵入和破壞鄰近的組織，甚至擴散至其他身體器官。大多數大腸癌是由一粒細小的瘜肉慢慢長大而形成。并非所有瘜肉都有致癌性，但有些會慢慢演變為癌症。 ‍")]), _c("div", [_vm._v("大腸癌是香港第二位最常見的癌症，佔癌症新症總數的15.8% *。及早發現大腸癌能增加完全康復的機率。ColoClear揉合現存兩種測試的優點，以最先進的技術檢測糞便樣本中的癌症和癌前病變生物標記物。與傳統的大腸鏡檢查比較，ColoClear不但是非入侵性的，而且簡單易用，更能於5個工作天內提供結果。")]), _c("div", [_vm._v("* 資料來源：香港衞生防護中心")])]), _c("el-collapse-item", {
    attrs: {
      title: "我可以如何購買採樣套裝？",
      name: "6"
    }
  }, [_c("div", [_vm._v("目前，您可在指定的醫思健康診所購買ColoClear測試套裝。詳情請參閱：地址與聯絡資料")])]), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "ColoClear是否在醫療保險的保障範圍内？",
      name: "7"
    }
  }, [_c("div", [_vm._v("ColoClear目前並不屬於醫療保險的保障範圍内，但我們正積極地與多間保險公司協商，尋求未來的合作機會。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "我可以從海外購買ColoClear嗎？",
      name: "8"
    }
  }, [_c("div", [_vm._v("現時，我們並不提供香港境外的送貨服務。我們正努力擴大服務範圍，請不時瀏覽hicircle.com/coloclear 網頁了解最新資訊。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "糞便基因測試如何運作？",
      name: "9"
    }
  }, [_c("div", [_vm._v("您的腸細胞幾乎每天都會脫落和更新，而異常的腸細胞亦會與正常的細胞一併脫落至您的糞便中。")]), _c("div", [_vm._v("大腸癌的發生發展不是單基因變異，而是多基因變異的複雜過程，因此只有多靶點檢測可有效檢出癌前病變以及早中晚期大腸癌並最大程度降低漏檢率。這項先進的技術是建基於基因突變和甲基化的研究。多項研究對比大腸癌患者惡性生長物基因，與健康人士細胞的糞便基因，都發現某些基因生物標記物與增加患上大腸癌的風險直接關連。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "多靶點糞便基因 (FIT-DNA) 測試是甚麼？",
      name: "10"
    }
  }, [_c("div", [_vm._v("多靶點糞便基因 (FIT-DNA) 測試技術屬新一代DNA技術，結合最先進的糞便基因技術和傳統的糞便免疫化學(FIT)測試技術。此項革命性技術可檢測多項生物標記和血紅蛋白，並分析樣本是否存有可能由癌症或瘜肉造成殘留血跡或異常基因。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "糞便免疫化學 (FIT) 、糞便潛血 (FOBT) 、多靶點糞便基因 (FIT-DNA)測試與大腸鏡檢查有甚麼分別？",
      name: "11"
    }
  }, [_c("div", [_vm._v("糞便免疫化學 (FIT) 測試使用抗體檢測大便隱血。樣本有隱血即表示腸道有出血現象，亦可能是大腸癌的先兆。糞便免疫化學測試只能從大腸較低部位偵測人類血液。")]), _c("div", [_vm._v("糞便潛血 (FOBT) 測試則是使用化學試劑來檢測肉眼無法看見的大便隱血。")]), _c("div", [_vm._v("而多靶點糞便基因 (FIT-DNA) 測試技術屬新一代DNA技術，結合最先進的糞便基因技術和傳統的糞便免疫化學(FIT)測試技術。此項革命性技術可檢測多項生物標記和血紅蛋白，並分析樣本是否存有可能由癌症或瘜肉造成殘留血跡或異常基因。")]), _c("div", [_vm._v("大腸鏡檢查是用於檢測大腸（結腸）和直腸變化或異常的檢查。有需要時，可在大腸鏡檢查期間利用內窺鏡切除瘜肉或異常組織，亦可抽取組織的樣本（活體組織）。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "ColoClear的準確度、靈敏度和特異性有多高？",
      name: "13"
    }
  }, [_c("div", [_c("p", [_vm._v("陰性預測值，或稱「真陰性率」，是其中一個了解篩檢的臨床顯著性的常用指標。ColoClear對大腸癌的陰性預測值高達99.6%，亦即ColoClear得出的陰性結果準確率為99.6%。")]), _c("p", [_vm._v("而ColoClear對大腸癌的靈敏度（真陽性率）則為95.5%，亦即ColoClear得出的陽性結果準確率為95.5%。")]), _c("p", [_vm._v("另外，ColoClear能以 63.5%的靈敏度偵測晚期腺瘤。晚期腺瘤比瘜肉更為嚴重，亦有更高機會發展成癌症。")]), _c("p", [_vm._v("ColoClear對大腸癌和晚期腺瘤的整體特異性 (真陰性，没有癌症或晚期腺瘤) 為87.1%。")])])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "ColoClear與市面上同類型的測試有甚麼分別？",
      name: "14"
    }
  }, [_c("div", [_c("p", [_vm._v("與其他獲准作臨床應用的糞便基因測試比較，ColoClear有更高的靈敏度。（資料來源）")]), _c("p", [_vm._v("ColoClear僅作篩檢用途，而非診斷性測試，測試結果亦不應視作為大腸癌的診斷。ColoClear可取代篩檢用途的大腸鏡檢查。如欲了解更多，用家應諮詢醫護專業人士意見。")]), _c("p", [_vm._v("「陰性」測試結果表示在糞便樣本中檢沒有大腸基因病變及/或便血，患大腸癌或腺瘤的機率較低，但不保證沒有患上大腸癌及/或晚期腺瘤。")]), _c("p", [_vm._v("「陽性」測試結果表示在糞便樣本中檢測到大腸基因病變及/或便血，可能與大腸癌病變有關連，但不保證其後進行的大腸鏡檢查一定會發現這些病狀。")])])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "ColoClear比大腸鏡檢查或乙狀結腸鏡檢查有甚麼優勝之處？",
      name: "15"
    }
  }, [_c("div", [_vm._v("ColoClear是非入侵性的篩檢，亦只需在家中進行。相對大腸鏡檢查或乙狀結腸鏡檢查，您不需要為進行ColoClear測試告假或休息，亦不需要調整飲食或藥物。另外，測試套裝附贈送貨服務，讓您舒適地在家中收集樣本。啟動套裝後，您亦可預約上門收取樣本服務。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "病人一般需要相隔多久進行檢查？我需要相隔多久使用ColoClear （恆常篩檢）？",
      name: "16"
    }
  }, [_c("div", [_vm._v("如您的大腸癌糞便基因測試結果為陰性，根據美國癌症協會的建議，您應在之後的每3年進行一次大腸癌篩檢。")]), _c("div", [_vm._v("香港癌症專家工作小組 (CEWG) 則建議您按照醫生的意見，每1至2年進行一次大腸癌篩檢（大便隱血測試）。")]), _c("div", [_vm._v("曾有家族大腸癌病史、遺傳或其他已知風險的高危人士可考慮在45歲以前開始進行大腸癌篩檢。美國癌症協會建議高危人士應每1至3年進行定期篩檢，您亦應向醫生查詢您的大腸癌風險。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "ColoClear能辨別瘜肉及/或早期癌變嗎？",
      name: "17"
    }
  }, [_c("div", [_vm._v("ColoClear是用於偵測大腸癌和良性腫瘤的輔助篩檢測試。陽性的結果表示大腸鏡檢查很大機會從您體內發現包括大腸癌、晚期腺瘤或良性腫瘤等的顯著病變，您應儘快安排進行診斷性大腸鏡檢查。陰性結果表示您患上大腸癌或晚期腺瘤（很大機會演化成腸癌的腺瘤性瘜肉）的機率較低。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "我可以如何就測試結果獲得協助？",
      name: "18"
    }
  }, [_c("div", [_vm._v("當您在指定的醫思健康診所購買ColoClear測試套裝，診所將為您安排一位醫生，為您詳盡解釋測試結果。您的報告將被分享至主診醫生的診所，並由診所的代表與您聯絡和跟進。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "我可以如何在購買前獲得協助？",
      name: "19"
    }
  }, [_c("div", [_vm._v("您可在購買前，與我們的合作診所預約諮詢服務。")]), _c("div", [_vm._v("香港仁和體檢 (詳情請參閱網頁)")]), _c("div", [_vm._v("旺角分店")]), _c("div", [_vm._v("地址：旺角亞皆老街8號朗豪坊辦公室大樓11樓全層")]), _c("div", [_vm._v("查詢熱線：(852) 2156 5856")]), _c("div", [_vm._v("營業時間：星期一 上午9時至下午7時星期二至星期六 上午8時15分至下午7時")]), _c("div", [_vm._v("星期日及公眾假期休息")]), _c("div", [_vm._v("銅鑼灣分店")]), _c("div", [_vm._v("地址：銅鑼灣告士打道280號世貿中心34樓3401-03室")]), _c("div", [_vm._v("查詢熱線：(852) 2152 8582")]), _c("div", [_vm._v("營業時間：星期一至星期六 上午8時30分至下午6時")]), _c("div", [_vm._v("星期日及公眾假期休息")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "此測試可以分別辨別癌症患者嗎？",
      name: "20"
    }
  }, [_c("div", [_vm._v("ColoClear是檢測大腸癌和良性腫瘤的輔助篩檢測試。陽性的結果並不一定代表您患有癌症。我們建議您向醫生諮詢，並根據您的健康狀況安排覆診。")])]) : _vm._e(), _vm.section9showMore ? _c("el-collapse-item", {
    attrs: {
      title: "此測試能取代大腸鏡檢查嗎？",
      name: "21"
    }
  }, [_c("div", [_vm._v("ColoClear僅作篩檢用途，而非診斷性測試，測試結果亦不應視作為大腸癌的診斷。ColoClear可取代篩檢用途的大腸鏡檢查。如欲了解更多，用家應諮詢醫護專業人士意見。")])]) : _vm._e()], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.section9showMore,
      expression: "!section9showMore"
    }],
    staticClass: "moreAsk"
  }, [_c("span", {
    staticClass: "blue",
    on: {
      click: function ($event) {
        _vm.section9showMore = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].section9_bottomButtonText))])]), false ? _c("div", {
    staticClass: "faq-button-box"
  }, [_c("div", {
    staticClass: "faq-button-title"
  }, [_c("a", {
    staticClass: "link faq",
    attrs: {
      href: "https://circlecoloclear.zendesk.com/hc/zh-tw",
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.langText[_vm.language].section9_bottomButtonText2))])])]) : _vm._e()], 1), _c("div", {
    staticClass: "section section-ten"
  }, [_c("el-collapse", {
    attrs: {
      accordion: ""
    },
    model: {
      value: _vm.sectionTenActiveName,
      callback: function ($$v) {
        _vm.sectionTenActiveName = $$v;
      },
      expression: "sectionTenActiveName"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      title: "References",
      name: "1"
    }
  }, [_c("div", [_vm._v("1.People's Rupublic of China. National Medical Products Administration. (2020). 体外诊断试剂产品注册技术审评报告: CSZ2000050.")]), _c("div", [_vm._v("2.United States of America. National Cancer Institute. (2022). Cancer Stat Facts: Colorectal Cancer. https://seer.cancer.gov/statfacts/html/colorect.html")]), _c("div", [_vm._v("3.Pan J. et al. (2020). Prevalence and risk factors for colorectal polyps in a Chinese population: a retrospective study. Scientific Reports. 10(1). doi: 10.1038/s41598-020-63827-6")]), _c("div", [_vm._v("4.Hong Kong SAR. Centre for Health Protection, Department of Health. (2022). Colorectal Cancer. https://www.chp.gov.hk/en/healthtopics/content/25/51.html")]), _c("div", [_vm._v("5.Hong Kong SAR. Centre for Health Protection, Department of Health. (2020). Non-Communicable Diseases Watch - Fighting Colorectal Cancer. https://www.chp.gov.hk/files/pdf/ncd_watch_january_2020.pdf")]), _c("div", [_vm._v("6.Vacante M. et al. (2018). Biomarkers in colorectal cancer: Current clinical utility and future perspectives. World J Clin Cases. 6(15): 869–881. doi: 10.12998/wjcc.v6.i15.869")]), _c("div", [_vm._v("7.Niedermaier T. et al. (2020). Stage-Specific Sensitivity of Fecal Immunochemical Tests for Detecting Colorectal Cancer: Systematic Review and Meta-Analysis. Am J Gastroenterol. 115(1): 56–69. doi: 10.14309/ajg.0000000000000465")]), _c("div", [_vm._v("8.Imperiale T. F. et al. (2019). Performance Characteristics of Fecal Immunochemical Tests for Colorectal Cancer and Advanced Adenomatous Polyps: A Systematic Review and Meta-analysis. Ann Intern Med. 170(5):319-329. doi: 10.7326/M18-2390")]), _c("div", [_vm._v("9.Mu J. et al. (2020). Plausibility of an extensive use of stool DNA test for screening advanced colorectal neoplasia. Clin Chim Acta. 501:42-47. doi: 10.1016/j.cca.2019.12.001")])])], 1)], 1), _vm._m(5), _c("div", {
    staticClass: "section section-12"
  }, [_vm.language === "zh " ? _c("img", {
    attrs: {
      src: require("./assets/section12/total.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("./assets/section12/total_en.png"),
      alt: ""
    }
  })])]) : _c("div", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.language === "zh" ? _c("img", {
    attrs: {
      src: require("./assets/mobile/total_zh.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language === "en" ? _c("img", {
    attrs: {
      src: require("./assets/mobile/total_en.png"),
      alt: ""
    }
  }) : _vm._e()])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "title-caption-2"
  }, [_c("span", {
    staticClass: "blue"
  }, [_vm._v("ColoClear by Circle")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "container-full w-container"
  }, [_c("div", {
    staticClass: "usp-wrapper"
  }, [_c("div", {
    staticClass: "usp-item"
  }, [_c("div", {
    staticClass: "usp-item-icon-box"
  }, [_c("img", {
    staticClass: "usp-item-icon",
    attrs: {
      src: require("./assets/svg/doctor.svg"),
      loading: "lazy",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "p"
  }, [_vm._v("採用新一代DNA技術，多靶點糞便基因(FIT-DNA)測試技術；技術發展成熟，媲美美國市場領導者")])]), _c("div", {
    staticClass: "usp-item"
  }, [_c("div", {
    staticClass: "usp-item-icon-box"
  }, [_c("img", {
    staticClass: "usp-item-icon",
    attrs: {
      src: require("./assets/svg/coloClear.svg"),
      loading: "lazy",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "p"
  }, [_vm._v("高達99.6%陰性預測值和96%靈敏度的準確結果，並經過針對亞洲人群的臨床研究驗證"), _c("sup", [_vm._v("1")])])]), _c("div", {
    staticClass: "usp-item"
  }, [_c("div", {
    staticClass: "usp-item-icon-box"
  }, [_c("img", {
    staticClass: "usp-item-icon",
    attrs: {
      src: require("./assets/svg/accredited.svg"),
      loading: "lazy",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "p"
  }, [_vm._v("唯一獲國家藥品監督管理局批准的大腸癌篩檢檢測"), _c("sup", [_vm._v("1")])])]), _c("div", {
    staticClass: "usp-item"
  }, [_c("div", {
    staticClass: "usp-item-icon-box"
  }, [_c("img", {
    staticClass: "usp-item-icon",
    attrs: {
      src: require("./assets/svg/house.svg"),
      loading: "lazy",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "p"
  }, [_vm._v("非入侵性及無痛，安在家中的採樣過程")])])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "container-full w-container"
  }, [_c("div", {
    staticClass: "screening-wrapper"
  }, [_c("div", {
    staticClass: "screening-title-box"
  }, [_c("h2", {
    staticClass: "h2"
  }, [_vm._v("大腸癌篩檢的重要性")])]), _c("div", {
    staticClass: "screening-tabbox"
  }, [_c("div", {
    staticClass: "s-tabs w-tabs",
    attrs: {
      "data-current": "Tab 1",
      "data-easing": "ease",
      "data-duration-in": "300",
      "data-duration-out": "100"
    }
  }, [_c("div", {
    staticClass: "s-tabs-menu w-tab-menu",
    attrs: {
      role: "tablist"
    }
  }, [_c("a", {
    staticClass: "s-tab-link w-inline-block w-tab-link w--current",
    attrs: {
      "data-w-tab": "Tab 1",
      id: "w-tabs-0-data-w-tab-0",
      href: "#w-tabs-0-data-w-pane-0",
      role: "tab",
      "aria-controls": "w-tabs-0-data-w-pane-0",
      "aria-selected": "true"
    }
  }, [_c("div", {
    staticClass: "s-tab-link-inner"
  }, [_c("div", {
    staticClass: "s-tab-link-title"
  }, [_vm._v("早期篩檢，是您保護自己的第一步")]), _c("div", {
    staticClass: "s-tab-link-subtext"
  }, [_vm._v("大腸癌是其中一種最有機會預防的癌症 —如及早發現，高達90%的患者能夠治療。每五個人中就有一個有大腸瘜肉。"), _c("sup", [_vm._v("2")]), _vm._v(" 大部分瘜肉都是良性的，少部分則有演變成癌症的風險。"), _c("sup", [_vm._v("3")]), _vm._v(" 瘜肉檢測和切除可以有效預防大腸癌的發展。")])]), _c("div", {
    staticClass: "s-tab-link-arrowbox"
  }, [_c("img", {
    staticClass: "s-tab-link-arrow",
    staticStyle: {
      transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      "transform-style": "preserve-3d"
    },
    attrs: {
      alt: "",
      loading: "lazy",
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/62665bad7e2254d94d95f63b_ChevronDown%20copy.svg"
    }
  })])]), _c("a", {
    staticClass: "s-tab-link w-inline-block w-tab-link",
    attrs: {
      "data-w-tab": "Tab 2",
      tabindex: "-1",
      id: "w-tabs-0-data-w-tab-1",
      href: "#w-tabs-0-data-w-pane-1",
      role: "tab",
      "aria-controls": "w-tabs-0-data-w-pane-1",
      "aria-selected": "false"
    }
  }, [_c("div", {
    staticClass: "s-tab-link-inner"
  }, [_c("div", {
    staticClass: "s-tab-link-title"
  }, [_vm._v("大腸癌是第二常見的癌症")]), _c("div", {
    staticClass: "s-tab-link-subtext"
  }, [_vm._v("2019年，本港錄得5,556宗大腸癌病例，佔整體癌症個案達15.8%。患上大腸癌的風險亦會因年紀增長而顯著上升。"), _c("sup", [_vm._v("4")])])]), _c("div", {
    staticClass: "s-tab-link-arrowbox"
  }, [_c("img", {
    staticClass: "s-tab-link-arrow",
    staticStyle: {
      transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg)",
      "transform-style": "preserve-3d"
    },
    attrs: {
      alt: "",
      loading: "lazy",
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/62665bad7e2254d94d95f63b_ChevronDown%20copy.svg"
    }
  })])]), _c("a", {
    staticClass: "s-tab-link w-inline-block w-tab-link",
    attrs: {
      "data-w-tab": "Tab 3",
      tabindex: "-1",
      id: "w-tabs-0-data-w-tab-2",
      href: "#w-tabs-0-data-w-pane-2",
      role: "tab",
      "aria-controls": "w-tabs-0-data-w-pane-2",
      "aria-selected": "false"
    }
  }, [_c("div", {
    staticClass: "s-tab-link-inner"
  }, [_c("div", {
    staticClass: "s-tab-link-title"
  }, [_vm._v("接近一半的大腸癌病例都在晚期時才發現")]), _c("div", {
    staticClass: "s-tab-link-subtext spec"
  }, [_vm._v("根據2017年的數據，49%的大腸癌病例都在晚期時才發現"), _c("sup", [_vm._v("5")]), _vm._v("，令治療非常困難。")])]), _c("div", {
    staticClass: "s-tab-link-arrowbox"
  }, [_c("img", {
    staticClass: "s-tab-link-arrow",
    staticStyle: {
      transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg)",
      "transform-style": "preserve-3d"
    },
    attrs: {
      alt: "",
      loading: "lazy",
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/62665bad7e2254d94d95f63b_ChevronDown%20copy.svg"
    }
  })])]), _c("a", {
    staticClass: "s-tab-link w-inline-block w-tab-link",
    attrs: {
      "data-w-tab": "Tab 4",
      tabindex: "-1",
      id: "w-tabs-0-data-w-tab-3",
      href: "#w-tabs-0-data-w-pane-3",
      role: "tab",
      "aria-controls": "w-tabs-0-data-w-pane-3",
      "aria-selected": "false"
    }
  }, [_c("div", {
    staticClass: "s-tab-link-inner"
  }, [_c("div", {
    staticClass: "s-tab-link-title"
  }, [_vm._v("甚麼人應進行大腸癌篩檢？")]), _c("ul", {
    staticClass: "s-tab-link-listbox",
    attrs: {
      role: "list"
    }
  }, [_c("li", {
    staticClass: "s-tab-link-list-item"
  }, [_vm._v("希望可以了解自己大腸健康狀況及偵測早期患大腸癌的風險的人士")]), _c("li", {
    staticClass: "s-tab-link-list-item"
  }, [_vm._v("有大腸癌家族或個人病史的人士")]), _c("li", {
    staticClass: "s-tab-link-list-item"
  }, [_vm._v("出現慢性便秘或腹脹等腸道症狀的人士。")])])]), _c("div", {
    staticClass: "s-tab-link-arrowbox"
  }, [_c("img", {
    staticClass: "s-tab-link-arrow",
    staticStyle: {
      transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg)",
      "transform-style": "preserve-3d"
    },
    attrs: {
      alt: "",
      loading: "lazy",
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/62665bad7e2254d94d95f63b_ChevronDown%20copy.svg"
    }
  })])])]), _c("div", {
    staticClass: "s-tabs-content w-tab-content"
  }, [_c("div", {
    staticClass: "s-tab-pane w-tab-pane w--tab-active",
    attrs: {
      "data-w-tab": "Tab 1",
      id: "w-tabs-0-data-w-pane-0",
      role: "tabpanel",
      "aria-labelledby": "w-tabs-0-data-w-tab-0"
    }
  }, [_c("div", {
    staticClass: "s-tab-img-box"
  }, [_c("img", {
    staticClass: "s-tab-img",
    attrs: {
      alt: "",
      loading: "eager",
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a98d2eb15d77_drop-1-tc.png"
    }
  })])]), _c("div", {
    staticClass: "s-tab-pane w-tab-pane",
    attrs: {
      "data-w-tab": "Tab 2",
      id: "w-tabs-0-data-w-pane-1",
      role: "tabpanel",
      "aria-labelledby": "w-tabs-0-data-w-tab-1"
    }
  }, [_c("div", {
    staticClass: "s-tab-img-box"
  }, [_c("img", {
    staticClass: "s-tab-img",
    attrs: {
      alt: "",
      loading: "eager",
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a9fee2b15d71_drop-2-tc.png"
    }
  })])]), _c("div", {
    staticClass: "s-tab-pane w-tab-pane",
    attrs: {
      "data-w-tab": "Tab 3",
      id: "w-tabs-0-data-w-pane-2",
      role: "tabpanel",
      "aria-labelledby": "w-tabs-0-data-w-tab-2"
    }
  }, [_c("div", {
    staticClass: "s-tab-img-box"
  }, [_c("img", {
    staticClass: "s-tab-img",
    attrs: {
      alt: "",
      loading: "eager",
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a95bd5b15d74_drop-3-tc.png"
    }
  })])]), _c("div", {
    staticClass: "s-tab-pane w-tab-pane",
    attrs: {
      "data-w-tab": "Tab 4",
      id: "w-tabs-0-data-w-pane-3",
      role: "tabpanel",
      "aria-labelledby": "w-tabs-0-data-w-tab-3"
    }
  }, [_c("div", {
    staticClass: "s-tab-img-box"
  }, [_c("img", {
    staticClass: "s-tab-img",
    attrs: {
      alt: "",
      loading: "eager",
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/626a4f064873b953554031a8_drop_4.png"
    }
  })])])])])]), _c("div", {
    staticClass: "screning-sliderbox-mobile"
  }, [_c("div", {
    staticClass: "s-slider w-slider",
    attrs: {
      "data-delay": "4000",
      "data-animation": "slide",
      "data-autoplay": "false",
      "data-easing": "ease",
      "data-hide-arrows": "false",
      "data-disable-swipe": "false",
      "data-autoplay-limit": "0",
      "data-nav-spacing": "3",
      "data-duration": "500",
      "data-infinite": "true",
      role: "region",
      "aria-label": "carousel"
    }
  }, [_c("div", {
    staticClass: "s-slider-mask w-slider-mask",
    attrs: {
      id: "w-slider-mask-1"
    }
  }, [_c("div", {
    staticClass: "s-slide w-slide",
    staticStyle: {
      transform: "translateX(0px)",
      opacity: "1"
    },
    attrs: {
      "aria-label": "1 of 4",
      role: "group"
    }
  }, [_c("div", {
    staticClass: "s-slide-wrapper"
  }, [_c("div", {
    staticClass: "s-slide-img-box"
  }, [_c("img", {
    staticClass: "s-slide-img",
    attrs: {
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a98d2eb15d77_drop-1-tc.png",
      loading: "eager",
      height: "",
      alt: ""
    }
  })]), _c("div", {
    staticClass: "s-slide-content-box"
  }, [_c("div", {
    staticClass: "s-slide-title"
  }, [_vm._v("及早發現潛在風險，就是保護自己的第一道防線")]), _c("div", {
    staticClass: "s-slide-subtext spec"
  }, [_vm._v("大腸癌是其中一種最有機會預防的癌症 —如及早發現，高達90%的患者能夠治療。每五個人中就有一個有大腸瘜肉。"), _c("sup", [_vm._v("2")]), _vm._v("大部分瘜肉都是良性的，少部分則有演變成癌症的風險。"), _c("sup", [_vm._v("3")]), _vm._v("瘜肉檢測和切除可以有效預防大腸癌的發展。 ")])])])]), _c("div", {
    staticClass: "s-slide w-slide",
    staticStyle: {
      transform: "translateX(0px)",
      opacity: "1"
    },
    attrs: {
      "aria-label": "2 of 4",
      role: "group",
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-wrapper",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-img-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("img", {
    staticClass: "s-slide-img",
    attrs: {
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a9fee2b15d71_drop-2-tc.png",
      loading: "eager",
      height: "",
      alt: "",
      "aria-hidden": "true"
    }
  })]), _c("div", {
    staticClass: "s-slide-content-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-title",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("大腸癌是第二常見的癌症")]), _c("div", {
    staticClass: "s-slide-subtext",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v(" 2019年，本港錄得5,556宗大腸癌病例，佔整體癌症個案達15.8%。患上大腸癌的風險亦會因年紀增長而顯著上升。"), _c("sup", {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("4")])])])])]), _c("div", {
    staticClass: "s-slide w-slide",
    staticStyle: {
      transform: "translateX(0px)",
      opacity: "1"
    },
    attrs: {
      "aria-label": "3 of 4",
      role: "group",
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-wrapper",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-img-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("img", {
    staticClass: "s-slide-img",
    attrs: {
      src: "https://assets-global.website-files.com/629dee46e8787763a3429aa1/62afed013249a95bd5b15d74_drop-3-tc.png",
      loading: "eager",
      height: "",
      alt: "",
      "aria-hidden": "true"
    }
  })]), _c("div", {
    staticClass: "s-slide-content-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-title",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("接近一半的大腸癌病例都在晚期時才發現")]), _c("div", {
    staticClass: "s-slide-subtext",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("根據2017年的數據，49%的大腸癌病例都在晚期時才發現 "), _c("sup", {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("5")]), _vm._v("，令治療非常困難。")])])])]), _c("div", {
    staticClass: "s-slide w-slide",
    staticStyle: {
      transform: "translateX(0px)",
      opacity: "1"
    },
    attrs: {
      "aria-label": "4 of 4",
      role: "group",
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-wrapper",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-img-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("img", {
    staticClass: "s-slide-img",
    attrs: {
      src: "https://assets-global.website-files.com/6260da32b1882d556e6de320/626a4f064873b953554031a8_drop_4.png",
      loading: "eager",
      height: "",
      alt: "",
      "aria-hidden": "true"
    }
  })]), _c("div", {
    staticClass: "s-slide-content-box",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("div", {
    staticClass: "s-slide-title",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("strong", {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("甚麼人應進行大腸癌篩檢？")])]), _c("ul", {
    staticClass: "s-tab-link-listbox",
    attrs: {
      role: "list",
      "aria-hidden": "true"
    }
  }, [_c("li", {
    staticClass: "s-tab-link-list-item",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("尋求更便利的篩檢的人士")]), _c("li", {
    staticClass: "s-tab-link-list-item",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("有結直腸癌家族或個人病史的人士")]), _c("li", {
    staticClass: "s-tab-link-list-item",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("出現慢性便秘或腹脹等腸道症狀的人士。")])])])])]), _c("div", {
    staticClass: "w-slider-aria-label",
    attrs: {
      "aria-live": "off",
      "aria-atomic": "true",
      "data-wf-ignore": ""
    }
  })]), _c("div", {
    staticClass: "s-slide-arrow w-slider-arrow-left",
    attrs: {
      role: "button",
      tabindex: "0",
      "aria-controls": "w-slider-mask-1",
      "aria-label": "previous slide"
    }
  }, [_c("div", {
    staticClass: "w-icon-slider-left"
  })]), _c("div", {
    staticClass: "s-slide-arrow w-slider-arrow-right",
    attrs: {
      role: "button",
      tabindex: "0",
      "aria-controls": "w-slider-mask-1",
      "aria-label": "next slide"
    }
  }, [_c("div", {
    staticClass: "w-icon-slider-right"
  })]), _c("div", {
    staticClass: "s-slide-nav w-slider-nav w-slider-nav-invert w-round"
  }, [_c("div", {
    staticClass: "w-slider-dot w-active",
    staticStyle: {
      "margin-left": "3px",
      "margin-right": "3px"
    },
    attrs: {
      "data-wf-ignore": "",
      "aria-label": "Show slide 1 of 4",
      "aria-pressed": "true",
      role: "button",
      tabindex: "0"
    }
  }), _c("div", {
    staticClass: "w-slider-dot",
    staticStyle: {
      "margin-left": "3px",
      "margin-right": "3px"
    },
    attrs: {
      "data-wf-ignore": "",
      "aria-label": "Show slide 2 of 4",
      "aria-pressed": "false",
      role: "button",
      tabindex: "-1"
    }
  }), _c("div", {
    staticClass: "w-slider-dot",
    staticStyle: {
      "margin-left": "3px",
      "margin-right": "3px"
    },
    attrs: {
      "data-wf-ignore": "",
      "aria-label": "Show slide 3 of 4",
      "aria-pressed": "false",
      role: "button",
      tabindex: "-1"
    }
  }), _c("div", {
    staticClass: "w-slider-dot",
    staticStyle: {
      "margin-left": "3px",
      "margin-right": "3px"
    },
    attrs: {
      "data-wf-ignore": "",
      "aria-label": "Show slide 4 of 4",
      "aria-pressed": "false",
      role: "button",
      tabindex: "-1"
    }
  })])])])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "section section-five"
  }, [_c("div", {
    staticClass: "w-embed-youtubevideo video"
  }, [_c("iframe", {
    staticStyle: {
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      "pointer-events": "auto"
    },
    attrs: {
      src: "https://www.youtube.com/embed/xiYQINQ8Oao?rel=0&controls=1&autoplay=0&mute=0&start=0",
      frameborder: "0",
      allow: "autoplay; encrypted-media",
      allowfullscreen: "",
      title: "ColoClear Instructional Video (TC)"
    }
  })])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "right"
  }, [_c("img", {
    attrs: {
      src: require("./assets/section7/img3.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "section section-11"
  }, [_c("a", {
    staticClass: "social-link fb w-inline-block",
    attrs: {
      href: "https://www.facebook.com/circlecoloclear",
      target: "_blank"
    }
  }), _c("a", {
    staticClass: "social-link insta w-inline-block",
    attrs: {
      href: "https://www.instagram.com/circlecoloclear/",
      target: "_blank"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };