import banner2 from './assets/banner2.png';
import sectionTab1 from './assets/section1/tab1.png';
import sectionTab2 from './assets/section1/tab2.png';
import sectionTab3 from './assets/section1/tab3.png';
import sectionTab4 from './assets/section1/tab4.png';
import sectionTab1_en from './assets/section1/tab1_en.png';
import sectionTab2_en from './assets/section1/tab2_en.png';
import sectionTab3_en from './assets/section1/tab3_en.png';
import sectionTab4_en from './assets/section1/tab4.png';
import sectionTwoImg1 from './assets/section2/img1.png';
import sectionTwoImg2 from './assets/section2/img2.png';
import sectionTwoImg3 from './assets/section2/img3.png';
import sectionTwoImg4 from './assets/section2/img4.png';
import sectionTwoImg1_en from './assets/section2/img1_en.png';
import sectionTwoImg2_en from './assets/section2/img2_en.png';
import sectionTwoImg3_en from './assets/section2/img3_en.png';
import sectionTwoImg4_en from './assets/section2/img4_en.png';
import langText from './lang';
import { mapState } from 'vuex';
import ResizeMixin from '@/layout/mixin/ResizeHandler';
export default {
  name: 'hk_index',

  data() {
    return {
      bannerList: [banner2],
      sectionTabImg: sectionTab1,
      sectionActiveNames: '1',
      sectionTabImgList: [sectionTab1, sectionTab2, sectionTab3, sectionTab4],
      sectionTwoTabs: ['技術', '偵測', '準確度', '套裝包含'],
      sectionTwoTabIndex: 0,
      sectionTwoImgList: [sectionTwoImg1, sectionTwoImg2, sectionTwoImg3, sectionTwoImg4],
      showSectionSixDialog: false,
      sectionNineActiveName: '0',
      sectionTenActiveName: '0',
      section9showMore: false,
      language: 'zh',
      // 1: 繁體中文, 2: 英文
      langText
    };
  },

  mixins: [ResizeMixin],
  watch: {
    language() {
      if (this.language === 'en') {
        this.sectionTabImgList = [sectionTab1_en, sectionTab2_en, sectionTab3_en, sectionTab4_en];
        this.sectionTwoTabs = ['Technology', 'Detection', 'Accuracy', 'What’s included'];
        this.sectionTwoImgList = [sectionTwoImg1_en, sectionTwoImg2_en, sectionTwoImg3_en, sectionTwoImg4_en];
      } else {
        this.sectionTabImgList = [sectionTab1, sectionTab2, sectionTab3, sectionTab4];
        this.sectionTwoTabs = ['技術', '偵測', '準確度', '套裝包含'];
        this.sectionTwoImgList = [sectionTwoImg1, sectionTwoImg2, sectionTwoImg3, sectionTwoImg4];
      }
    }

  },

  created() {
    this.language = this.$route.query.lang.indexOf('en') > -1 ? 'en' : 'zh';
  },

  computed: { ...mapState({
      device: state => state.device
    })
  },

  mounted() {
    console.log(this.device);
  },

  methods: {
    pathTo(path) {
      window.location.href = path;
    },

    handleSelect() {},

    handleChange(val) {
      console.log(val);
    },

    notice() {
      this.$message({
        message: '建設中...',
        type: 'info',
        offset: 100,
        duration: 5000
      });
    }

  }
};